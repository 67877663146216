$error-color: #FF4963;
$font-black-color: #4C5356;
$secondary-color: #004666;
$primary-color: #66E4EE;
$border-color-dark: #A8ADB3;
$border-color-light: #E5E5E5;
$grey-color: #D8D8D8;

$border-color-qa: rgba(168, 173, 179, 0.5);
$subtext-label-color: #F5F5F5

