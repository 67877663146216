@import "variables";

.qa-box {
  margin-top: 32px;

  .ant-row.qa-row {
    border-top: 1px solid $border-color-qa;
  }

  div.ant-row.qa-row:last-child {
    margin-bottom: 0 !important;
  }

  .ant-row:last-child:not(.switch) {
    border-bottom: 1px solid $border-color-qa;
  }

  .switch {
    padding-top: 0
  }

  .switch-label {
    width: 50%;

    span {
      font-size: 16px;
      line-height: 24px;
    }
  }


 textarea {
    height: 100%;
    border-radius: 0;
  }

 .qa-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.qa-inputs {
  .input-box {
    width: 100%;
  }

  div.ant-row {
    padding-top: 10px !important;
  }
}

.confirmation-info {
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 30px;
}
