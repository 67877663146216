@import "variables";

.radiobox {
  .ant-radio-inner {
    border-radius: 0;
    border: 1px solid $font-black-color;
    width: 20px;
    height: 20px;

    &::after {
      border-radius: 0;
      background-color: #66E4EE;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
    }
  }

  .ant-radio-wrapper span {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: $secondary-color;

    &:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: $font-black-color
    }
  }

  label {
    padding-top: 20px;
  }
}
