@font-face {
    font-family: "VWAG TheSans";
    src: url("VWAGTheSans-Bold.eot");
    src: url("VWAGTheSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("VWAGTheSans-Bold.woff") format("woff"),
    url("VWAGTheSans-Bold.woff2") format("woff2"),
    url("VWAGTheSans-Bold.svg#VWAGTheSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: "VWAG TheSans";
    src: url("VWAGTheSans-Regular.eot");
    src: url("VWAGTheSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("VWAGTheSans-Regular.woff") format("woff"),
    url("VWAGTheSans-Regular.woff2") format("woff2"),
    url("VWAGTheSans-Regular.svg#VWAGTheSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}
