@import "variables";


.status-block-title, .status-block {
    background: $secondary-color;

    span {
        color: #fff
    }
}

.status-block-title {
    padding: 16px 18px;

    span {
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
    }
}

.status-block {
    padding: 12px 20px;

    span {
        font-size: 22px;
        line-height: 28px;
    }

    margin-bottom: 50px;
}

.status-row {
    margin-bottom: 20px;
}

.status-text-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $secondary-color
}

.status-text-name {
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    color: $font-black-color
}

.add-doc {
    span {
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        padding-left: 10px;
    }
    margin-bottom: 40px;
}

.reject-info {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    padding: 20px;
}
