@import "variables";

.navigate-buttons {
  width: 100%;
  height: 44px;
  margin-bottom: 52px;

  .button {
    height: 100%;
    cursor: pointer;
    border-bottom: 1px solid $border-color-dark;

    &.active {
      border: 1px solid $border-color-dark;
      border-bottom: none;
      border-top: 2px solid $secondary-color;

      span {
        line-height: 42px;
        color: $secondary-color;
      }
    }

    span {
      padding: 12px;
      line-height: 44px
    }

    &:hover span {
      color: $secondary-color
    }
  }
}
