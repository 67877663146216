@import '~antd/dist/antd.css';

$error: #FF4963;
$black: #4C5356;
$blue: #004666;

html, body {
  position: relative;
  height: 100vh;
}

.btn-block {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;

    button {
        margin: 0 8px;
    }
}

.pin-error, .error, .ant-typography.error {
  color: $error
}

.App {
  text-align: center;
}

#root, .btn-block {
  margin: auto;
  max-width: 1000px;
  background-color: #FFF;
}

.ant-form-item-has-error .suggestions-input {
  border-color: #ff4d4f
}

/*AUTOFILL*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: #FFF;
  -webkit-text-fill-color: $black
}

.input_login {
  width: 200px
}

.calculator-request-button {
  padding-top: 20px
}

.ant-typography, span, li, .ant-slider-mark-text,
.ant-form-item-explain,
a,
.ant-picker-header-view {
  font-family: 'VWAG TheSans';
  color: $black
}


.ant-popover-arrow {
  display: none;
}

.ant-popover-inner {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  max-width: 165px;
}

.ant-popover-inner-content {
  font-family: VWAG TheSans;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  padding: 12px;
}

.ant-input:focus, .ant-input:hover, .ant-input-focused,
.ant-input:focus + .ant-input-group-addon, .ant-input:hover + .ant-input-group-addon, .ant-input-focused + .ant-input-group-addon,
.date-picker .ant-picker:hover,
.date-picker .ant-picker-focused,
.ant-input-number-focused,
.dadata:hover input,
.dadata input:focus {
  border-color: $blue;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.ant-picker.ant-picker-disabled:hover,
.dadata:hover input[disabled] {
  border-color: #D8D8D8;
  cursor: not-allowed;
}

.ant-form-item-has-error .ant-input:focus + .ant-input-group-addon,
.ant-form-item-has-error .ant-input:hover + .ant-input-group-addon,
.ant-form-item-has-error .ant-input-focused + .ant-input-group-addon {
  border-color: #ff4d4f
}

.ant-form-item-has-error .ant-input:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}

.navigate-buttons span,
.dropdown span,
.switch span,
.slider span,
.checkbox span,
.radiobox span,
.result-box span:not(.calculator-result-text-bold) {
  font-size: 18px;
}

/*FORM*/

.ant-form-item {
  width: 100%;
  margin-bottom: 0
}

/*RESULT*/
.result-box .calculator-result-text-bold {
  font-size: 30px;
  line-height: 36px;
  color: $blue;
  font-weight: bold;
}

.result-box .result-row {
  padding-bottom: 28px
}

.result-box .title {
  padding-bottom: 40px
}

.calculator-result-text-bold.disabled {
  color: #C4C4C4
}

.result-box .calculator-result-text-bold.additional {
  font-size: 18px;
}


.ant-form-input-control-input-content {
  display: flex;
}

.ant-form-item-control-input-content {
  flex: 1 1 0%;
}

/*RESULT*/

.result-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 40px
}

.ant-form-item-has-error .ant-form-item-explain {
  color: $error;
  font-size: 12px;
  line-height: 16px;
}

/*LINK*/

.ant-anchor-ink {
  display: none;
}

.ant-anchor-wrapper {
  margin: 0;
}

.ant-anchor-link {
  padding: 0
}

.ant-anchor-link,
.ant-anchor-link-active > .ant-anchor-link-title,
.ant-anchor-link-title,
a:hover {
  color: $blue;
  font-size: 18px;
  text-decoration: underline;
  line-height: 28px;
}

/*CORRESPONDENCE*/
.correspondence .ant-card-bordered {
  background: #F5F5F5;
  border: 1px solid rgba(168, 173, 179, 0.5);
  border-radius: 0px;
  margin-bottom: 24px;
}

.correspondence .ant-card-head {
  border-bottom: 1px solid rgba(168, 173, 179, 0.5);;
  font-family: VWAG TheSans;
  font-size: 18px;
  line-height: 20px;
  color: $black;
  min-height: unset;
  padding: 0;
}

.correspondence .ant-card-head-title {
  padding: 10px 20px;
}

.correspondence .ant-card-body {
  padding: 25px 20px;
}

.correspondence textarea {
  height: 155px;
  resize: none;
  margin-top: 10px;
}
.correspondence .ant-input {
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  line-height: 28px;
  border: 1px solid #D8D8D8;
  border-radius: 0;
  margin-bottom: 16px;
}

.correspondence .correspondence-answer {
  font-size: 18px;
  line-height: 20px;
}



