@import "variables";
/*STEP*/

.step-box {
  .ant-steps {
    padding: 20px 17px;
    background: #F2F2F2;
    border-radius: 8px;
    margin-bottom: 55px;
  }

  .ant-steps-label-vertical {
    .ant-steps-item-tail {
      padding: 17px 29px;
      margin-left: 66px;
    }

    .ant-steps-item-content {
      max-width: 130px;
      width: auto;
    }

    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
      border: 2px solid $primary-color
    }

    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon span,
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: $secondary-color;
    }

    &:not(.ant-steps-vertical) .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon {
      background-color: $secondary-color;
      border-color: $secondary-color
    }

    &:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
      height: 60px;
      width: 60px;
      border: 2px solid $primary-color;
      background-color: transparent;
      margin-left: 35px;
    }
  }

  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #FFF;
  }
}

.step-box .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.step-box .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  font-size: 18px !important;;
  font-weight: bold;
  top: 12px !important;
}

.step-box .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.step-box .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $secondary-color;
}

.step-box .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
.step-box .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.step-box .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $primary-color;
  height: 2px;
  border-radius: 0;
}

.step-box .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.step-box .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.step-box .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.step-box .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  font-size: 14px;
  color: $font-black-color;
  line-height: 18px;
}
