@import "variables";

.checkbox {
  display: flex;

  &.small .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  &.small .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #006384;
    border-top: 0;
    border-left: 0;
    width: 7px;
    height: 12px;
  }

  .ant-checkbox-inner {
    width: 28px;
    height: 28px;
    border-radius: 0;
    border: 1px solid #66E4EE;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #66E4EE;
      border: 0;

      &::after {
        border: 2px solid #006384;
        border-top: 0;
        border-left: 0;
        width: 10px;
        height: 16px;
      }
    }

    &::after {
      border: #66E4EE;
      animation: 0s !important;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
  }

  .checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
  .checkbox .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-color: #66E4EE
  }

  &.small label {
    align-items: center;
  }
}

.checkbox-yes-no {
  &.checkbox {
    margin: 20px 0 18px;
  }

  .ant-checkbox + span {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $secondary-color;
  }

  label {
    padding-top: 16px;
  }
}
