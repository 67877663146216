@import "variables";

.ant-btn {
  height: 44px;
  border: none;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: 'VWAG TheSans';
  color: $secondary-color;

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 70, 102, 0.24);
  }

  &:active {
    box-shadow: none;
  }

  &.ant-btn:focus, &.ant-btn:active, &.ant-btn:hover {
    color: $secondary-color;
  }
}

.button-primary {
  .ant-btn {
    padding: 12px 55px;
    background-color: rgba(102, 228, 238, 0.9);
    border-radius: 0;
  }

  .ant-btn[disabled], .ant-btn.disabled {
    background-color: rgba(216, 216, 216, 0.9);
      color: #828A92;
  }

  .ant-btn[disabled]:hover, .ant-btn.disabled:hover {
    background-color: rgba(216, 216, 216, 0.9);
  }

  .ant-btn:hover {
    background-color: rgba(179, 242, 247, 0.9);
  }

  &:active .ant-btn {
    background-color: rgba(97, 211, 222, 0.9);
  }
}

.button-cancel {
  .ant-btn {
    padding: 0px 20px;
    background-color: $error-color;
    border-radius: 0;
      span {
          color: #fff;
      }
  }
}

.button-secondary {
  .ant-btn {
    padding: 11px 54px;
    border: 1px solid #004969;
    border-radius: 0;
    border-collapse: collapse;
  }


}

.button-primary .thin-button,
.button-secondary .thin-button {
  padding: 12px 20px
}

.button-with-icon {
    svg {
        position: absolute;
        bottom: 14px;
        left: 19px;
    }

    button.ant-btn {
        padding: 0 18px 0 40px;
    }
}
