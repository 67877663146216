@import "variables";

/*PIN MODAL*/
.modal.ant-modal.upload-modal {
  width: auto !important;
}

.modal .ant-modal-close-x {
  color: $secondary-color;
}

.pin-code-modal .ant-modal-body {
  padding: 45px 40px;
}

.modal .ant-modal-content {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.pin-code-modal .ant-modal-body form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.pin-code-modal span {
  font-size: 18px;
}

.pin-code-modal .pin-code-message {
  padding-bottom: 56px;
  display: inline-table;
}

.pin-code-modal .input-box {
  width: 100%;
  margin: auto;
  padding-top: 8px;
}

.pin-code-modal .button-primary {
  display: flex;
  justify-content: center;
}

.pin-code-modal .ant-form-item-explain {
  align-self: center;
}

.pin-code-modal .pin-code-timer {
  color: $secondary-color;
}

.pin-code-modal .pin-code-error {
  color: $error-color;
  font-size: 12px;
  width: 100%;
}

.ant-tooltip {
  display: none
}

/*HELPER*/
.ant-modal-wrap {
  height: 100%;
  width: 100%;
}

.helper-box {
  margin: 100px;
}

.helper-box.modal .ant-modal-close-x {
  color: $secondary-color;
  position: relative;
  top: 12px;
  right: -5px;
}

.helper-box span, .helper-box ul li {
  font-size: 18px;
  line-height: 28px;
}

.helper-box .ant-btn span {
  line-height: normal;
}

.helper-box ul li {
  padding-top: 18px;
}

.helper-box ul {
  margin: 0;
  padding: 0 0 0 20px;
}

.helper-box .ant-modal-body {
  padding: 40px;
  max-width: 900px;
}

.helper-box.modal .ant-modal-footer {
  border-top: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px 40px;
  margin-top: -10px;
}

.helper-box.modal .ant-modal-footer button:first-child {
  margin-right: 10px;
}

.dialog-modal .ant-modal-body {
  padding: 46px;
}

.dialog-header {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: $secondary-color;
  padding-bottom: 10px;
}

.dialog-message {
  font-size: 18px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: $secondary-color;
}

.dialog-conditions-box {
  border: 0;
  margin-bottom: 26px;
  padding: 0 40px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);

  .result-row {
    justify-content: flex-start;
    padding: 16px 0;
    font-size: 14px;

    .calculator-result-text-bold {
      color: $secondary-color;
    }

    .calculator-result-text-changed {
      color: $error-color;
    }

    & > span {
      padding-right: 5px;
    }
  }

  .calculator-row-info {
    border-top: 1px solid rgba(168, 173, 179, 0.5)
  }

  .calculator-row-info:first-child {
    border-top: none;
  }
}

.helper-text.info-text {
  padding: 30px 0
}

.cancel-info {
  padding: 30px 0
}
