@import "variables";

.switch {
  flex-wrap: nowrap;

  .ant-switch {
    border: 1px solid $border-color-dark;
    border-radius: 0;
    height: 26px;
    width: 55px;
    background-color: transparent;
    padding-top: 10px;
    margin-right: 8px;

    &:after, &:before {
      top: 2px;
      background-color: $grey-color;
    }

    &:after {
      border-radius: 0;
      height: 20px;
      width: 20px;
      margin-left: 1px;
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 20px;
    }

    &:not(.ant-switch-disabled):active::before {
      width: 1px;
    }

    .ant-click-animating-node {
      animation: 0s !important;
    }

    &.vertical-switch {
      padding-top: 0;

      .vertical-switch-text {
        font-size: 14px;
        padding-left: 10px
      }
    }
  }

  .ant-switch-checked::after {
    margin-left: -2px;
    background-color: $primary-color;
  }
}





