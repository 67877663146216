@import "variables";

.collapse-box {
  border: 0;
  margin-bottom: 26px;
  border-radius: 8px;

  .ant-collapse-header {
    font-family: VWAG TheSans;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    color: $secondary-color;
    padding: 19px 20px 21px 40px
  }

  .collapse-icon {
    position: absolute;
    right: 40px;
    top: 19px;

    span {
      padding-right: 5px;
      color: $secondary-color;
      font-size: 14px;
    }
  }

  .ant-collapse-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  }

  .result-row {
    justify-content: flex-start;
    padding: 16px 0;

    .calculator-result-text-bold {
      font-size: 14px;
      color: $secondary-color;
    }

    & > span {
      padding-right: 5px;
    }
  }

  .calculator-row-info {
    border-top: 1px solid rgba(168, 173, 179, 0.5)
  }

  .ant-collapse-content {
    border-top: 0
  }
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.collapse-box .ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 8px;
}

.ant-collapse > .ant-collapse-item {
  border: 0
}

.collapse-box .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 8px 8px;
}

.collapse-box .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px 20px
}

