@import "variables";

.dadata-input {
  position: relative;
}

.suggestions-highlighting {
  background-color: unset;
  font-weight: 400;
  color: unset;
  padding-right: 0;
}

.suggestions-suggestion,
.suggestions-hint {
  padding: 11px 12px !important;
  border-bottom: 1px solid rgba(168, 173, 179, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.suggestions-suggestion {
  font-size: 18px;
  line-height: 22px;

  &:last-child {
    border: none
  }
}

.suggestions-hint {
  font-family: VWAG TheSans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #828A92;
}

.suggestions-container {
  margin-top: 8px
}

.dadata {
  input {
    padding: 0 36px 0 48px;
    text-overflow: ellipsis;
    width: 100%;
  }

  .additional {
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: $secondary-color;
    cursor: pointer
  }
}

.dadata-clear-icon {
  position: absolute;
  top: 14px;
  right: 12px;
}

suggestions-wrapper {
  position: fixed;
  z-index: 9999;
}

.dadata-search-icon {
  position: absolute;
  top: 14px;
  left: 12px;
}

input::placeholder,
input:-ms-input-placeholder {
  color: $border-color-dark !important
}
