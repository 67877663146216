@import "variables";

.upload-box {
  padding-bottom: 40px;

  label {
    padding-top: 14px;
  }

  .drag-box {
    border: 1.5px dashed $border-color-dark;
    box-sizing: border-box;
    border-radius: 0px;
    margin-left: 16px;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    align-items: center;

    &.highlight {
      border-color: $border-color-light;
    }

    span {
      color: $border-color-dark;
      font-size: 18px;
    }
  }

  .ant-btn {
    padding: 12px 20px
  }
}

.upload-box.ant-upload.ant-upload-drag .ant-upload,
.ant-upload-list-item-info {
  padding: 0;
}

.upload-box.ant-upload.ant-upload-drag-hover .drag-box {
  border-color: $primary-color;
  background-color: rgba(102, 228, 238, 0.2);
}

.upload-box.ant-upload.ant-upload-drag {
  padding-top: 14px;
  border: 0;
  background: #fff;
}

.upload-list-item-name {
  padding: 0 0 9px;
  margin-top: 6px;
  border-bottom: 1px solid $border-color-qa;
  display: flex;
  justify-content: space-between;

  svg {
    top: 6px;
    position: relative;
  }
}

.ant-upload-text-icon {
  display: none;
}

.ant-upload-list-item-card-actions {
  opacity: 1;
  top: 7px
}

.upload-text {
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.ant-upload-list-item {
  margin-top: 15px;

  &:hover .ant-upload-list-item-info {
    background-color: #fff;
  }
}

.upload-box-label-title, .additional-documents-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $secondary-color;
}

.upload-modal .additional-documents .upload-box-label-title {
  font-size: 18px;
}

.additional-documents {
  padding-top: 20px
}

.upload-modal {
  .ant-modal-title {
    font-family: VWAG TheSans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.54);
  }

  .ant-modal-header {
    padding: 20px 40px
  }

  .ant-modal-body {
    padding: 36px 40px 0;
    overflow: overlay;
    max-height: 560px;
  }
}

-webkit-scrollbar {
  width: 1em;
}

-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


