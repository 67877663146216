@import "variables";

.date-picker {
  span {
    font-size: 18px;
  }

  .ant-picker {
    border-radius: 0;
    width: 100%;
    height: 44px;
  }

  .ant-picker-clear {
    display: none;
  }
}

.date-picker .ant-picker-input input,
.ant-picker-footer a,
.ant-picker-header-view {
  font-size: 18px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $secondary-color;
}

.ant-picker-header-view button:hover,
.ant-picker-header button span,
.ant-picker-today-btn,
.ant-picker-today-btn:hover {
  color: $secondary-color;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $border-color-dark;
}
