@import "variables";

.input-label {
  margin-bottom: 8px;
}

.input-box {
  span {
    font-size: 18px;
  }

  .suffix {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 44px;
    font-weight: bold;
    color: $secondary-color;
  }

  .helper-text span {
    padding-top: 6px;
  }
}

.nowrap span {
  white-space: nowrap;
}

.helper-text span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin: 0;
  line-height: 16px;
  padding: 20px 0;
}


.dropdown {
  .ant-select-arrow svg {
    position: absolute;
    top: 3px;
    right: 8px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 44px;
  }
}

.dropdown-menu {
  border: 1px solid $secondary-color;
  border-radius: 0;
  padding: 0;

  .ant-select-item {
    margin: 0 10px;
    padding: 10px 0;
    line-height: 22px;
    border-bottom: 1px solid $border-color-dark;
  }

  .ant-select-item:last-child {
    border: none;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $subtext-label-color
}

.ant-select-item-option-content {
  white-space: normal;
}

.dropdown .ant-select-single .ant-select-selector,
.input-box input,
.input-number .ant-input-number,
.dropdown-menu .ant-select-item {
  font-size: 18px;
  border-radius: 0;
}

.dropdown .ant-select-single .ant-select-selector,
.input-box input,
.input-number .ant-input-number {
  height: 44px;
}


.dropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.dropdown-menu {
  line-height: 44px;
  color: $font-black-color;
  font-weight: bold;
}


.ant-select-focused.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.input-number .ant-input-number:hover,
.ant-select:focus {
  border-right-width: unset;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: $secondary-color;
}

.input-number .ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number-input {
  background-color: transparent;
}

.ant-input, .dadata input {
  border: 1px solid #D8D8D8
}


