@import "variables";

.slider {
  padding: 0
}

.ant-slider {
  margin: 15px 0 20px
}

span.ant-slider-mark-text {
  font-size: 14px;
}

.slider-input {
  .ant-input-prefix {
    position: relative;
    left: -25px;
  }

  .ant-input {
    text-align: end;
    padding: 0
  }

  .ant-input-number-input {
    text-align: right;
    padding: 0
  }

  .ant-input-number-disabled, .input-slider-number .ant-input-disabled {
    background-color: #fff;
  }
}

.ant-slider-handle {
  width: 28px;
  height: 28px;
  margin-top: -12px;
  background-color: #fff;
  border: solid 1px $primary-color;
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 7px;
    left: 7px;
    content: '';
    width: 12px;
    height: 12px;
    background-color: $secondary-color;
    border-radius: 50%;
  }
}

.ant-slider-mark {
  top: 16px
}

.ant-slider-track {
  background-color: $primary-color;
}

.ant-slider-rail {
  background-color: $grey-color;
  height: 2px;
  top: 5px;
}

.ant-slider-dot {
  position: absolute;
  top: -7px;
  width: 1px;
  height: 18px;
  border: 1px solid $grey-color;
  background-color: $grey-color;
  border-radius: 0;
  cursor: pointer;
}

.ant-slider-dot,
.ant-slider-dot:first-child,
.ant-slider-dot:last-child {
  margin-left: 0
}

.ant-slider.marked .ant-slider-mark span {
  display: none;

  &:last-child {
    right: 0;
  }
}

.ant-slider.marked .ant-slider-mark span:first-child,
.ant-slider.marked .ant-slider-mark span:last-child {
  left: auto !important;
  transform: none !important;
  display: block;
}

.unmarked .ant-slider-dot {
  display: none
}

.slider-input .input-slider-number input,
.slider-input .input-slider-number .ant-input-number {
  color: $secondary-color;
  font-weight: bold;
  font-size: 18px;
  width: 80px;
  border: none;
  background-color: transparent;
}

.input-slider-number-box {
  border-bottom: 1px solid $border-color-light;
}

.input-slider-number-suffix {
  font-family: VWAG TheSans;
  font-weight: bold;
  font-size: 18px;
  padding-left: 5px;
  display: inline-block;
}

